<template>
  <div>
    <div id="box-feed">
      <div
        v-for="(item, index) in newsitems"
        :key="`item-${index}`"
        class="news-item box"
      >
        <h2>{{ item.headline }}</h2>
        <span class="news-date">{{ item.date }}</span>
        <img :src="item.imgUrl" :alt="item.imgAlt" />
        <div class="news-text">
          <div
            v-for="(paragraph, paraIndex) in item.text"
            :key="`item-${index}-index-para-${paraIndex}`"
          >
            <p>{{ paragraph }}</p>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsView",
  components: {},
  data() {
    return {
      newsitems: [
        {
          headline: "Further 'Going Sane On The Road' dates announced",
          imgUrl: "/zg_dates_2.jpg",
          imgAlt: "Tour dates",
          date: "2 August 2024",
          text: [],
        },
        {
          headline: "ZG announce 'Going Sane On The Road' dates",
          imgUrl: "/zg_dates.jpg",
          imgAlt: "Tour dates",
          date: "4 April 2024",
          text: [],
        },
        {
          headline: "'I Think I'm Going Sane' Single out now!",
          imgUrl: "/sane.jpg",
          imgAlt: "I Think I'm Going Sane",
          date: "18 March 2024",
          text: ["Listen to it on all streaming services now."],
        },
        {
          headline: "ZG to play Purple Turtle, Reading",
          imgUrl: "/turtleposter.jpg",
          imgAlt: "Darkspace Ltd.",
          date: "22 January 2024",
          text: [],
        },
        {
          headline: "'Darkspace Ltd.' EP is out now!",
          imgUrl: "/DS.png",
          imgAlt: "Darkspace Ltd.",
          date: "12 January 2024",
          text: [
            "Tumbling relentlessly through a desaturated spiritual void, we are barraged with depression, dissociation and derealisation.",
            "This EP is an out-of-body experience that emulates the feeling of being forced to look within yourself... and seeing nothing there.",
            "The intention is not to disturb however, but to use darkness as a window to people stuck in such a state of nihilism and say - you are not alone.",
          ],
        },
        {
          headline: "ZG announce triple bill at The Butler, Reading",
          imgUrl: "/BUTLER FEB 24 - POSTER.png",
          imgAlt: "Gig poster",
          date: "10 September 2024",
          text: [],
        },
        {
          headline: "ZG played debut gig",
          imgUrl: "/debut.jpg",
          imgAlt: "Darkspace Ltd.",
          date: "11 November 2024",
          text: ["And it went rather well."],
        },
        {
          headline: "ZG announce debut gig",
          imgUrl: "/GIG POSTER 6.png",
          imgAlt: "Gig poster",
          date: "10 September 2024",
          text: [],
        },
        {
          headline: "'Quadrillionth' EP is out now!",
          imgUrl: "/QD.png",
          imgAlt: "Quadrillionth",
          date: "8 July 2022",
          text: [
            "Comprising of three blistering tracks, it takes the listener on an intense journey into the psyche and creative process.",
            "Although clocking in at only 16 minutes in total length, this work of manic maximalism goes wide and deep in to the weird world of the creativity and the limits it pushes us to.",
          ],
        },
      ],
    };
  },
};
</script>
