<template>
  <div id="box-feed">
    <div class="box">
      <h2>Contact us</h2>
      <div class="para-alone">
        <p>
          We are currently booking live shows, please direct all enquires to:
        </p>
        <br />
        <p>zendengreenpurp@gmail.com</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowsView",
  components: {},
};
</script>
