import Vue from "vue";
import VueRouter from "vue-router";
import NewsView from "../views/NewsView.vue";
import AboutView from "../views/AboutView.vue";
import ShowsView from "../views/ShowsView.vue";
import ContactView from "../views/ContactView.vue";
import SetlistsView from "../views/SetlistsView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "NewsView",
    component: NewsView,
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
  },
  {
    path: "/shows",
    name: "shows",
    component: ShowsView,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
  },
  {
    path: "/setlists",
    name: "setlists",
    component: SetlistsView,
  },
  { path: "*", component: NewsView },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
