<template>
  <div id="app" class="zg-bg">
    <div id="zenden">
      <header>
        <img src="@/assets/zg_logo.png" alt="zenden greenpurp" />
      </header>
      <nav>
        <router-link to="/">NEWS</router-link>
        <router-link to="/about">ABOUT</router-link>
        <router-link to="/shows">SHOWS</router-link>
        <router-link to="/contact">CONTACT</router-link>
      </nav>
      <div class="links">
        <div>
          <a href="https://www.facebook.com/ZendenGreenpurp"
            ><i class="fa-brands fa-square-facebook"></i
          ></a>
          <a
            href="https://www.instagram.com/zenden_greenpurp?igsh=M3hsbHExYWQwYmcz"
            ><i class="fa-brands fa-square-instagram"></i
          ></a>
          <a
            href="https://open.spotify.com/artist/1oqqiAC4EqHLxDCQsmIHxy?si=qG4vF85NSVqWF_Jp74xABg"
            ><i class="fa-brands fa-spotify"></i
          ></a>
        </div>
        <div>
          <a href="https://zendengreenpurp.bandcamp.com/album/quadrillionth"
            ><i class="fa-brands fa-bandcamp"></i
          ></a>
          <a href="https://www.youtube.com/channel/UCtwS5L3rQRmx0n7DXFZmPqQ"
            ><i class="fa-brands fa-youtube"></i
          ></a>
          <a
            href="https://music.apple.com/us/artist/zenden-greenpurp/1633751713"
            ><i class="fa-brands fa-apple"></i
          ></a>
        </div>
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  computed: {},
};
</script>

<style></style>
