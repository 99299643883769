<template>
  <div id="box-feed">
    <div>
      10-Nov- 2023: Take On The World, Void Observation Technician, I Think I'm
      Going Sane, Limitless Power, Black Album Cover, Doctor Obnoxious, Take On
      The World
    </div>
    <div>
      29-Jan-2024: Take On The World, Black Album Cover, I Think I'm Going Sane,
      Limitless Power, Doctor Obnoxious, Void Observation Technician
    </div>
    <div>
      24-Feb-2024: Take On The World, Black Album Cover, Seahorses, I Think I'm
      Going Sane, Limitless Power, Void Observation Technician, Doctor
      Obnoxious, Take On The World
    </div>
    <div>
      19-Apr-2024: Take On The World, I Think I'm Going Sane, Limitless Power,
      Black Album Cover, Doctor Obnoxious
    </div>
  </div>
</template>

<script>
export default {
  name: "SetlistsView",
};
</script>

<style></style>
